import React from 'react';
import Chat from './Chat';

function GenerateAssets() {
    return (
        <div>
            <div>
                <Chat />
            </div>
        </div>
    );
}

export default GenerateAssets;
