import React, { useContext, useState } from 'react'
import chatGPT from '../../utils/chatGPT';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import Papa from 'papaparse';
import { FlashMessagesContext } from './BuildConsole';
import axios from 'axios';


function Sidebar({setConsoleFormRows,consoleFormRows,setStory,preview,setPreview,setSelectedImages,selectedImages,uploadClick,setUploadClick}) {

    const initialPrompt = 'Start writing a 2 hour movie script where each response describes a small part of the movie in the following format. Have multiple characters in the story introduce each character slowly. Each script line starts with symbol "###" for easy text parsing. "###" symbol is followed by name of the character speaking a dialogue or "None" if the script is explaining the scene. This is followed by delimiter ":" and then the text of the script. Describe first Five minutes for';
    const continuePrompt = "continue the script for the next Five minute";
  
    const [uploadsSearch,setUploadsSearch]=useState("");
    const [aiClick,setAiClick]=useState(false);
    const [inputMessage, setInputMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([{ role: 'system', content: initialPrompt }]);
    const [inputDisabled, setInputDisabled] = useState(false);
    const [continueClick,setContinueClick]=useState(false)
    const [sidebarLoading,setSidebarLoading]=useState(false);
    const [showdownloads,setShowDownloads]=useState(false);
    const [aiChoice,setAiChoice]=useState(0);
    const [imageURL,setImageURL]=useState("");
    const [promptSubmitted,setPromptSubmitted]=useState(false);
    const [promptHistory,setPromptHistory]=useState([])
    const [promptError,setPromptError]=useState(false)
    
    
    const {setFlashMessages}=useContext(FlashMessagesContext);

    const sidebarClickStyle = {background:"#0a0a0a",borderRadius:"13px 0px 0px 13px",paddingRight:"2rem",marginLeft:"1.5rem",}

    const handleFileSelect = (event) => {
      event.preventDefault();
      const files = Array.from(event.target.files);
      setSelectedImages((prevSelectedImages) => [
        ...prevSelectedImages,
        ...files.map((file) => ({
          name:file.name,
          previewURL: URL.createObjectURL(file),
        })),
      ]);
    };

    const triggerPreview = () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        setPreview(!preview);
        setStory(consoleFormRows);
        if(document.body.style.overflow==="hidden") document.body.style.overflow="auto";
        else document.body.style.overflow="hidden";
    }

    const handleDrop = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        setSelectedImages((prevSelectedImages) => [
            ...files.map((file) => ({
            file,
            previewURL: URL.createObjectURL(file),
            })),...prevSelectedImages,
        ]);
    };

    const removeImage = (index) => {
        setSelectedImages((prevSelectedImages) => {
            const updatedImages = [...prevSelectedImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });
    };

    const sendMessage = async (e, inputMsg = inputMessage) => {

        e.preventDefault();
        setInputDisabled(true);
        setSidebarLoading(true);
        var messages = chatHistory.concat({ role: 'user', content: inputMsg })
        const chatGPTResponse = await chatGPT(messages);
        messages = messages.concat({ role: 'assistant', content: chatGPTResponse })
        // Initializing the formRows with Latest  CHat History
        const responseRows = chatGPTResponse
        .split("###")
        .slice(1)
        .map((line) => {
            const [speaker, dialogue] = line.split(":");
            return { speaker: speaker.trim(), dialogue: dialogue.trim() };
        });
        let tempConsoleRows=consoleFormRows;
        console.log(tempConsoleRows)
        tempConsoleRows=responseRows.map((responseRow)=>{
            return ({
            background:'',
            customBgDropClick:false,
            character:responseRow.speaker,
            characterImage:'',
            characterImageDropClick:false,
            dialogue:responseRow.dialogue
            })
        });
        console.log(tempConsoleRows)
        setConsoleFormRows((previousConsoleFormRows)=> {
            return [...previousConsoleFormRows,...tempConsoleRows]
        });
        setChatHistory(messages);
        setSidebarLoading(false);
        setContinueClick(false)
    };

    const continueStory = async (event) => {
        event.stopPropagation();
        setContinueClick(true)
        await sendMessage({ preventDefault: () => {} }, continuePrompt);
    };

    const handleAssistantInputChange=(e)=>{
        setInputDisabled(false);
        setInputMessage(e.target.value);
    }

    //To create CSV File
    const createCsvContent=(data)=>{
        const csv = Papa.unparse(data);
        return csv;
    }

    //To Create Zip File
    const saveZipFile = async ()=>{
        const zip = new JSZip();
        
        const downloadImages = selectedImages.map(async(image)=>{
            const response = await fetch(image.previewURL,{mode:"no-cors"});
            const blob = await response.blob();
            zip.file(image.name,blob);
        })
        await Promise.all(downloadImages);
        saveAs(await zip.generateAsync({ type: 'blob' })) 
    }

    //Uploading CSV File to the Firebase storage
    const saveCSVFile = async () => {
        //Chosing the data required for Story CSV or Excluding the Dropdown Click Handlers
        const csvData=consoleFormRows.map((consoleFormRow,index)=>{
        return ({background:consoleFormRow.background,
                speakerName:consoleFormRow.character,
                speakerImage:consoleFormRow.characterImage,
                dialogue:consoleFormRow.dialogue,
                })})
        const csvContent = createCsvContent(csvData);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob);
        setFlashMessages((messages)=>{
            let temp=messages;
            temp.push("CSV File Downloaded Successfully ");
            return temp;
        })
    }

    const showSidebarMenu = (sidebarItem)=>{
        switch(sidebarItem){
            case "uploads":
                setUploadClick(!uploadClick);
                setAiClick(false)
                setShowDownloads(false)
                break;
            case "ai":
                setAiClick(!aiClick);
                setUploadClick(false);
                setShowDownloads(false);
                break;
            case 'downloads':
                setShowDownloads(!showdownloads);
                setAiClick(false);
                setUploadClick(false);
                break;
            default:
                console.log("Invalid Switch Case")
        }

    }

    const downloadPromptImage =async (imageUrl,filename) => {
    
        await fetch(imageUrl,{mode:'no-cors'})
          .then(async response => await response.blob())
          .then(blob => {
            const objectUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = objectUrl;
            link.download = filename;
            link.click();
            URL.revokeObjectURL(objectUrl);
          })
          .catch(error => {
            console.error('Error:', error);
          });
      };

    const makeAPIRequestToRunpod = async(prompt,negativePrompt)=>{
        const options = {
            method: 'POST',
            url: 'https://api.runpod.ai/v2/stable-diffusion-v2/runsync',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              authorization: '91VURCRQFQVK3VL7ULJJE0JK4U8J4KIXZZ3DJMRG'
            },
            data: {
              input: {
                prompt: prompt,
                negative_prompt: negativePrompt.length==0 ? " ":negativePrompt,
                height:768,
                width:512,
                prompt_strength: 0,
                num_outputs: 1,
                num_inference_steps: 50,
                guidance_scale: 7.5,
                scheduler: 'KLMS',
                seed: 0
              },
              webhook: 'string'
            }
          };
        
          await axios.request(options)
            .then(async function (response) {
                setImageURL(response.data.output[0].image)
                setSelectedImages((prevSelectedImages) => [
                    {
                    name:prompt+" "+response.data.id,
                    previewURL: response.data.output[0].image,
                    },...prevSelectedImages,
                ]);
                setPromptHistory((prevPromptHistory)=>{
                    return [
                        {prompt,negativePrompt,imageURL:response.data.output[0].image},
                        ...prevPromptHistory
                    ]
                })
            })
            .catch(function (error) {
                console.error(error);
                setPromptError(true)
            });
    }

    const handlePromptSubmit = (e)=>{
        setPromptSubmitted(true)
        e.preventDefault();
        const prompt=e.target.prompt.value;
        const negativePrompt = e.target.negativePrompt.value;
        makeAPIRequestToRunpod(prompt,negativePrompt)
    }

    const refreshPromptForm= ()=>{
        setPromptSubmitted(false)
        setImageURL("")
        setPromptError(false)
    }

  return (
            <div className="sidebar">
                <div className="sidebarButtons">
                    <div className="sidebarButton" onClick={()=> showSidebarMenu("uploads")} style={uploadClick ? sidebarClickStyle:{}}>
                        <img alt='Upload' src='cloud_upload_24px_rounded.svg' />
                        <div className="buttonText">Uploads</div>
                    </div>
                    <div className="sidebarButton" onClick={triggerPreview}>
                        <img alt='Preview' src='smartphone.svg' height={"30px"} />
                        <div className="buttonText">Preview</div>
                    </div>                
                    <div className="sidebarButton" onClick={()=>showSidebarMenu("ai")} style={aiClick ? sidebarClickStyle : {}}>
                        <img alt='AI' src='ai.png' height={"50px"} style={{marginBottom:"0.5rem"}} />
                        <div className="buttonText">Assistant</div>
                    </div>
                    <div className="sidebarButton" onClick={()=> showSidebarMenu("downloads")} style={showdownloads ? sidebarClickStyle : {}}>
                        <img alt='Downloads' src='download_24px_rounded.svg' height={"40px"} style={{marginTop:"-0.5rem",marginBottom:"0.5rem"}}/>
                        <div className="buttonText">Downloads</div>
                    </div>
                </div>
                {
                    uploadClick &&
                    <div className="sidebarMenu">
                    <form className='searchImagesForm'>
                        <div><img alt="Search" className='' loading='eager'  src='search_24px_rounded.svg'/></div>
                        <input 
                          className='ctaInput' 
                          type='text' 
                          value={uploadsSearch} 
                          placeholder='Search Uploads'
                          onChange={(e)=> setUploadsSearch(e.target.value)}
                        />
                    </form>
                    <label htmlFor='image-upload'><div className="ctaButton uploadButton">Upload Files</div></label>
                    <div className="fileUploadForm">
                    <form>
                        <label htmlFor="image-upload" className="image-upload-label">
                        Drag to Upload
                        </label>
                        <input
                        id="image-upload"
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleFileSelect}
                        onDrop={handleDrop}
                        />
                    </form>
                    <div className="image-preview">
                        {selectedImages.map((image, index) => {
                        if(image.name.toLowerCase().includes(uploadsSearch.toLowerCase()))
                        return (<div key={index} className="image-preview-item">
                            <img className='imagePreview' src={image.previewURL} alt={`Preview ${index}`} />
                            <img alt='Cross' height={"20px"} className='imageRemoveButton' onClick={() => removeImage(index)} src='add_24px.png' />
                        </div>)
                        })}
                    </div>
                    </div>
                    </div>
                }
                {
                  aiClick==true && aiChoice===0 ?
                  <div className="sidebarMenu aiClick generateOptions">
                    <div className="storyOption">
                    <div className="ctaButton uploadButton" onClick={()=> setAiChoice(1)}>Generate Story <img src='chevron_right_24px.svg' /></div>
                    
                    </div>
                    <div className="storyOption">
                    <div className="ctaButton uploadButton" onClick={()=> setAiChoice(2)}>Generate Images <img src='chevron_right_24px.svg' /></div>                        
                    </div>
                  </div>
                    : aiClick==true && aiChoice===1 ?
                  <div className="sidebarMenu aiClick">
                  <div className="backButton" onClick={()=> setAiChoice(0)}><img src='chevron_right_24px.svg' />Back</div>
                  <div className="sectionHeader sidebarMenuHeading">AI Writing Assistant</div>
                  <form onSubmit={sendMessage} >
                    <input
                      type="text"
                      value={inputMessage}
                      onChange={handleAssistantInputChange}
                      className='ctaInput'
                      placeholder='Enter a story scenario'
                    /> 
                    {
                    !inputDisabled && !sidebarLoading &&
                    <button 
                      type='submit' 
                      className="ctaButton uploadButton" 
                      >
                    CREATE
                    </button>
                    }

                      {
                        sidebarLoading && 
                      <>
                      <div className="loader"></div>
                      <div className="loaderMessage">Generating ...</div>
                      </>
                      }
                  </form>
                  {
                        inputDisabled && !sidebarLoading &&
                        <button 
                          type="button" 
                          onClick={continueStory} 
                          className="ctaButton resetFormButton"
                        >
                        Continue +
                        </button>
                  }
                  </div> : aiClick==true && aiChoice===2 &&
                  <div className="sidebarMenu aiClick generateImages">
                    <div className="backButton" onClick={()=> setAiChoice(0)}><img src='chevron_right_24px.svg' />Back</div>
                    <div className="sectionHeader sidebarMenuHeading">
                        Generate images for your story
                    </div>
                    {
                        imageURL.length==0 ?
                        <>
                        <form className='imageGenerator' onSubmit={handlePromptSubmit}>
                        <input type='text' name='prompt' className='promptInput' placeholder='Prompt' required/>
                        <input type='text' name='negativePrompt' className='promptInput' placeholder='Negative Prompt'/>
                        { 
                         !promptError &&
                            <button type='submit' className='ctaButton uploadButton'>
                            {
                                promptSubmitted ? 
                                <div className="loader"></div> :
                                "GENERATE"
                            }
                            </button>
                        }
                        </form>
                        {
                            promptError &&
                            <button type='' className='ctaButton red' onClick={refreshPromptForm}>
                            <img src="refresh_24px.png" alt='Try Again' height={"25px"} width={"25px"} />
                            ERROR
                            </button>
                        }
                        </> :
                        <div className="promptResult">
                            <img className='imagePreview' src={imageURL} alt={"Prompt Image"} />
                            <img src="refresh_24px.png" alt='Try Again' height={"25px"} width={"25px"} onClick={refreshPromptForm}/>
                        </div>
                    }
                  </div>

                }
                {
                    showdownloads &&
                    <div className="sidebarMenu downloads">
                        <button 
                          type="button" 
                          onClick={saveCSVFile} 
                          className="ctaButton resetFormButton  uploadButton"
                        >
                        <img alt='CSV' src="csv.png" loading='eager' height={"30px"}/>
                            Download Story
                        </button>
                        <button 
                          type="button" 
                          onClick={saveZipFile} 
                          className="ctaButton resetFormButton"
                        >
                        <img alt='Images' src='picture.png' loading='eager' height={"30px"}/>
                            Download Images
                        </button>
                    </div>
                }

            </div>
  )
}

export default Sidebar