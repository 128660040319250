import React from 'react'

function ImageGameScreen({characterImageURL,character,dialogue}) {
  return (
    <div className='imageGameScreen'>
        {characterImageURL && <img src={characterImageURL} className='characterImage' alt='Character Image'/> }
        <div className="gameScreen">
        <div className='growDiv'><div className="speakerDialogue"><b>{character}</b> <br/> {dialogue} </div></div>
        </div>   
    </div>
  )
}

export default ImageGameScreen