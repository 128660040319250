import React, { useEffect, useState } from 'react';
import './StoryEditor.css';
import { Link } from 'react-router-dom';
import GameScreen from '../GameScreen/GameScreen';
import MobilePreview from '../MobilePreview/MobilePreview';

const StoryEditor = () => {

  const initialFormRows = localStorage.getItem("formData")
    ? JSON.parse(localStorage.getItem("formData"))
    : [{ background:'',speaker: '',speakerImage:'', dialogue: '' }];
    
  const [formRows, setFormRows] = useState(initialFormRows);
  const [preview,setPreview] = useState(false);
  const [start,setStart] = useState(false);
  const [dialogueIndex,setDialogueIndex]=useState(0);
  const [story,setStory]=useState();

  useEffect(()=>{
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  },[])

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formRows));
  }, [formRows]);

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    setFormRows((prevFormRows) => {
      const updatedRows = [...prevFormRows];
      updatedRows[index] = {
        ...updatedRows[index],
        [name]: value,
      };
      return updatedRows;
    });
  };

  const handleAddRow = (index) => {
    setFormRows((prevFormRows) => {
      const updatedRows = [...prevFormRows];
      updatedRows.splice(index + 1, 0, {background:'', speaker: '',speakerImage:'', dialogue: ''});
      return updatedRows;
    });
  };

  const handleDeleteRow = (index) => {
    setFormRows((prevFormRows) => {
      const updatedRows = [...prevFormRows];
      updatedRows.splice(index, 1);
      return updatedRows;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const csvContent = `data:text/csv;charset=utf-8,${convertToCSV(formRows)}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'form_data.csv');
    document.body.appendChild(link);
    link.focus();
  };

  const convertToCSV = (data) => {
    const headers = ['Speaker', 'Dialogue', 'Column3'];
    const csvRows = data.map((row) => `${row.speaker},${row.dialogue},${row.col3}`);
    return headers.join(',') + '\n' + csvRows.join('\n');
  };

const handleResetForm = () => {
  setFormRows([{ speaker: '', dialogue: '', col3: '' }]);
  localStorage.removeItem('formData');
};

const triggerPreview = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    setPreview(!preview);
    setStory(formRows);
    if(document.body.style.overflow=="hidden") document.body.style.overflow="auto";
    else document.body.style.overflow="hidden";
}
  
  return (
    <>      
    <div className='onMobile'>
    <div className="errorContainer">
        <div className="errorText" style={{color:"white",minHeight:"400px",padding:"8rem 0"}}>
            <div className="errorLightText subText" style={{marginBottom:"3rem"}}>Best viewed on desktop</div>
            <div className="error subText green" style={{marginBottom:"3rem"}}>SWITCH TO DESKTOP VIEW FOR BETTER EXPERIENCE</div>
            <div className="errorHeavyText subText" style={{marginBottom:"3rem"}}></div>
            <Link to='/'> <div className="ghostButton errorButton" style={{justifyContent:"center",width:"50%",margin:"auto"}}> BACK TO HOME PAGE</div></Link>
        </div>
    </div>
    </div>
    {
      preview &&     
      <MobilePreview setPreview={setPreview} story={story} />
    }
    <div className="storyEditor">
      <div className="sectionHeader editorHeading">Story Editor</div>
      <form className="storyEditorForm" onSubmit={handleSubmit}>
      {
            formRows.length>5 && <div className="absoluteButton"><button type='submit' className="imageButton"><img width={"40px"} src="download_24px.png"/></button></div>
        }
        <div className="topButtons">
        <button type="submit" className='ctaButton downloadcsv'><img src="download_24px.png"/> Download CSV</button>
        <button type="button" onClick={triggerPreview} className='ctaButton resetFormButton'><img src="smartphone.png" height={"40px"} /> Preview</button>
        <button type="button" onClick={handleResetForm} className="ctaButton resetFormButton"><img src="history_24px.png" height={"30px"}/>Reset Form</button>
        </div>
       { 
        formRows.length!=0 && 
        <div className="columnHeaders" style={{margin:"0.75rem 0"}} >
            <div className="columnHeader" >
                Background
            </div>           
            <div className="columnHeader" >
                Character
            </div>           
            <div className="columnHeader" >
                Character Image
            </div>
            <div className="columnHeader" >
                Dialogue
            </div>
            <div className="columnHeader" > Column 3</div>
        </div>}
        {formRows.map((row, index) => (
          <div key={index} className="rowContainer" >
            <div className="editorInput">
            <input 
              className='ctaInput'
              type="text"
              name="speaker"
              value={row.speaker}
              placeholder="Speaker"
              onChange={(event) => handleInputChange(event, index)}
            />
            <input 
              className='ctaInput'
              type="text"
              name="dialogue"
              value={row.dialogue}
              placeholder="Dialogue"
              onChange={(event) => handleInputChange(event, index)}
            />
            <input 
              className='ctaInput'
              type="text"
              name="col3"
              value={row.col3}
              placeholder="Col3"
              onChange={(event) => handleInputChange(event, index)}
            />
            <button
              type="button"
              onClick={() => handleDeleteRow(index)}
              className="deleteRowButton deleteRow"
            >
            <img src="delete_24px.png" height={"24px"} alt="Delete Button"/>
            </button>
            </div>
            <button
              type="button"
              onClick={() => handleAddRow(index)}
              className="addRowButton"
            >
            <img src="add_24px.png" loading="eager"/>Add
            </button>
          </div>
        ))}
      </form>
    </div>
    </>
  );
};

export default StoryEditor;
