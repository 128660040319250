import { AnimationOnScroll } from "react-animation-on-scroll";

//CSS in home.css
function ConsoleLoading() {

    const loadingMessages=["Click on the Preview Button in the sidebar to Preview your story on mobile",
    "Click Assistant button on the sidebar to generate stories using a prompt",
    "Click on the Downloads button in the sidebar to download the created story",
    "Press Tab or Escape to close the Input Image Dropdown",
    "Press CTRL+Y to delete an input row",
    "Press CTRL+I to add an input row",
    "Press CTRL+Q to undo the delete operation",];

    const generateRandomLoadingMessage=() => {
        let randomIndex=Math.trunc((Math.random())*(loadingMessages.length));
        return loadingMessages[randomIndex]
    }

  return (
    <div className='loadingScreenContainer'>
    <div className="brandTextImage">
    <img src='wuriLogoLarge.png' height={75} />
    </div>  
    <div className="load-wrapp">
      <div className="load-2">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
    <div className="insights insightsHeading">DID YOU KNOW</div>
    <div className='insights'>{generateRandomLoadingMessage()}</div>
    </div>
  )
}

export default ConsoleLoading