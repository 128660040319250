import { createContext, useEffect, useState } from 'react'
import './buildConsole.css'
import MobilePreview from '../MobilePreview/MobilePreview';
import ConsoleLoading from './ConsoleLoading';
import SubmitForm from './SubmitForm';
import Sidebar from './Sidebar';
import './modalScreen.css'
import ConfirmBox from './ConfirmBox';
import FlashMessages from './FlashMessages';

export const FlashMessagesContext = createContext(null);

function BuildConsole() {


    const initialFormRows = localStorage.getItem("consoleFormData")
    ? JSON.parse(localStorage.getItem("consoleFormData"))
    : [{ background:'',customBgDropClick:false,character:'',characterImage:'',characterImageDropClick:false,dialogue: '',}];
    
    const [selectedImages, setSelectedImages] = useState([]);
    const [consoleFormRows, setConsoleFormRows] = useState(initialFormRows);
    const [preview,setPreview] = useState(false);
    const [story,setStory]=useState();
    const [loading,setLoading]=useState(true);
    const [imagesMap,setImagesMap]=useState(new Map());
    const [undoStack,setUndoStack]=useState([]);
    const [showNextScreen,setShowNextScreen]=useState(false);
    const [uploadClick,setUploadClick]=useState(false);
    const [showConfirm,setShowConfirm]=useState(false);
    const [flashMessages,setFlashMessages]=useState([]);
    const [projectName,setProjectName]=useState("Untitled Project");



    useEffect(() => {
      localStorage.setItem("consoleFormData", JSON.stringify(consoleFormRows));
    }, [consoleFormRows]);

    useEffect(()=> {
      const tempMap=imagesMap;
      for(let i=0;i<selectedImages.length;i++)
        tempMap.set(selectedImages[i].name,selectedImages[i].previewURL)

      setImagesMap(tempMap);
    },[selectedImages])

    useEffect(() => {
      const timeout=setTimeout(() => {
        setLoading(false);
      }, 4000);
      document.title=projectName
      return ()=>{
        clearTimeout(timeout);
      }
    }, []);

    useEffect(()=> {
      const restoreRows=(event)=> {
        if(event.ctrlKey===true && event.code==="KeyQ" && undoStack.length!=0)
        {
          const pop=undoStack[undoStack.length-1];
          handleConsoleAddRow(pop[0]-1)
          setConsoleFormRows((prevFormRows) => {
            const updatedRows = [...prevFormRows];
            updatedRows[pop[0]] = {
              ...pop[1]
            };
            return updatedRows;
          });
          setUndoStack((previousUndoStack)=>{
            const temp=previousUndoStack.slice(0,previousUndoStack.length-1)
            return temp
          })
          setFlashMessages((messages)=>{
            let temp=messages;
            temp.push("Row Restored Successfully");
            return temp;
          })
        }
      }
      window.addEventListener("keydown",restoreRows);
      return ()=> window.removeEventListener("keydown",restoreRows);
    })

    useEffect(()=>{
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    },[])

    const handleConsoleInputChange = (event, index) => {
      event.stopPropagation()
      const { name, value } = event.target;
      setConsoleFormRows((prevFormRows) => {
        const updatedRows = [...prevFormRows];
        updatedRows[index] = {
          ...updatedRows[index],
          [name]: value,  
        };
        return updatedRows;
      });
    };

    const handleConsoleAddRow = (index) => {
      setConsoleFormRows((prevFormRows) => {
        const updatedRows = [...prevFormRows];
        updatedRows.splice(index + 1, 0, { background:'',customBgDropClick:false,character: '',characterImage:'',characterImageDropClick:false, dialogue: '',});
        return updatedRows;
      });
    };

    const handleConsoleDeleteRow = (index) => {

      setUndoStack((previousUndoStack)=>{
        let temp = previousUndoStack || [];
        temp.push([index,consoleFormRows[index]])
        return temp;
      })

      setConsoleFormRows((prevFormRows) => {
        const updatedRows = [...prevFormRows];
        updatedRows.splice(index, 1);
        return updatedRows;
      });
    };

    
    
    const handleDropdownImageClick = (index,bgName)=> {
      setConsoleFormRows((prevFormRows) => {
        const updatedRows = [...prevFormRows];
        updatedRows[index] = {
          ...updatedRows[index],
          customBgDropClick:false,
          background:bgName,
        };
        return updatedRows;
      });
    }
    
    const handleCharacterDropdownImageClick = (index,characterImageName)=> {
      setConsoleFormRows((prevFormRows) => {
        const updatedRows = [...prevFormRows];
        updatedRows[index] = {
          ...updatedRows[index],
          characterImage:characterImageName,
          characterImageDropClick:false,
        };
        return updatedRows;
      });
    }

    const handleDropdownClick = (index,show,name)=> {
      if(show===true)
      {
        setConsoleFormRows((prevFormRows) => {
          const updatedRows = [...prevFormRows];
          for(let i=0;i<updatedRows.length;i++) 
          {
            updatedRows[i].customBgDropClick=false;
            updatedRows[i].characterImageDropClick=false;
          }
          updatedRows[index] = {
            ...updatedRows[index],
            [name]:true,
          };
          return updatedRows;
        });
      }
      if(show===false)
      {
        setConsoleFormRows((prevFormRows) => {
          const updatedRows = [...prevFormRows];
          for(let i=0;i<updatedRows.length;i++) 
          {
            updatedRows[i].customBgDropClick=false;
            updatedRows[i].characterImageDropClick=false;
          }
          updatedRows[index] = {
            ...updatedRows[index],
            [name]:false,
          };
          return updatedRows;
        });
      }
    }

    const handleDropdownKeyPress = (event,index)=> {
        if(event.code==="KeyY" && event.ctrlKey===true)   handleConsoleDeleteRow(index);
        else if(event.code==="KeyI" && event.ctrlKey===true)    handleConsoleAddRow(index);
        else if(event.target.name==="background" && (event.key==="Escape" || event.key==="Tab"))    
        handleDropdownClick(index,false,"customBgDropClick")
        else if(event.target.name==="characterImage" && (event.key==="Escape" || event.key==="Tab")) 
        handleDropdownClick(index,false,"characterImageDropClick")
    }

    const handleKeyPress = (event,index)=> {
      if(event.ctrlKey===true && event.code==="KeyY") handleConsoleDeleteRow(index);
      if(event.ctrlKey===true && event.code==="KeyI") handleConsoleAddRow(index);
    }

    const triggerPreview = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      setPreview(!preview);
      setStory(consoleFormRows);
      if(document.body.style.overflow==="hidden") document.body.style.overflow="auto";
      else document.body.style.overflow="hidden";
  }

  const clearInput = (index,name)=> {
    setConsoleFormRows((prevFormRows) => {
      const updatedRows = [...prevFormRows];
      updatedRows[index] = {
        ...updatedRows[index],
        [name]: "",
      };
      return updatedRows;
    });
  }

  const handleConsoleReset=()=>{
    setConsoleFormRows([{ background:'',customBgDropClick:false,character:'',characterImage:'',characterImageDropClick:false,dialogue: '',}])
    setFlashMessages((messages)=>{
      let temp=messages;
      temp.push("Reset Successful");
      return temp;
    })
  }

  const handleUploadSidebarButtonClick = ()=>{
    setUploadClick(!uploadClick);
  }

  const updateDocumentTitle = (e)=>{
    if(projectName.trim().length===0)
    {
      setProjectName("Untitled Project")
      document.title="Untitled Project"
    } 
    else
    document.title=projectName;
  }

  if(loading===true)
  return <ConsoleLoading/>
    
  return (
    <FlashMessagesContext.Provider value={{flashMessages,setFlashMessages}}>
    <div className='buildConsoleContainer'>
        {
          preview && <MobilePreview imagesMap={imagesMap} setPreview={setPreview} story={story}/>
        }
        {
          flashMessages.map((message,index)=>{
            return <FlashMessages key={index} message={message}/>
          })
        }
        {
          showNextScreen && 
          <SubmitForm 
            setShowNextScreen={setShowNextScreen} 
            selectedImages={selectedImages}
            consoleFormRows={consoleFormRows}  
          />
        }
        {
          showConfirm &&
          <ConfirmBox setShowConfirm={setShowConfirm}/>
        }
        <div className="buildNavbar">
            <div className="buildNavbarLogo" onClick={()=>setShowConfirm(true)}><img src='wuriLogoLarge.png' height={"45px"} alt="Wuri Logo" /></div>
            <div className='buildButtons'>
            <input className='projectNameInput' value={projectName} onBlur={updateDocumentTitle} onChange={(e)=>setProjectName(e.target.value)} />
            <button type="button" onClick={handleConsoleReset} className="ctaButton resetFormButton">
              <img src="history_24px.png" height={"25px"} alt='Reset'/>
                Reset
            </button>
            <div className="ctaButton buildButton" onClick={()=> setShowNextScreen(true)}>NEXT</div>
            </div>
        </div>
        <div className="sidebarAndEditor">
            <Sidebar 
              setConsoleFormRows={setConsoleFormRows} 
              uploadClick={uploadClick}
              setUploadClick={setUploadClick}
              setPreview={setPreview}
              consoleFormRows={consoleFormRows} 
              setStory={setStory}
              preview={preview}
              selectedImages={selectedImages}
              setSelectedImages={setSelectedImages}
            />

            <div className="editor consoleEditor">
                  {consoleFormRows.map((row, index) => (
                    <div key={index} className="rowContainer consoleEditorContainer" >
                      <div className="editorInput consoleEditorInputs">
                      {
                        <div className="ctaInput customDropdownInput posRel">
                        <div className="inputAndCross">
                          <input
                           type='text'
                           className='searchBarForDropdown'
                           placeholder='Background Image'
                           value={row.background}
                           name='background'
                           onFocus={()=> handleDropdownClick(index,true,"customBgDropClick")}
                           onChange={(event) => handleConsoleInputChange(event, index)}
                           onKeyDown={(event)=> handleDropdownKeyPress(event,index)}
                          />
                          {
                            row.customBgDropClick ? 
                            <img src='chevron_right_24px.svg'
                                 className='cancelDropdown'
                                 height={"20px"}
                                 alt='Close'
                                 onClick={()=> handleDropdownClick(index,false,"customBgDropClick")}
                            />
                            : row.background.length!=0 &&
                            <img src='add_24px.png'
                                 className='cancelDropdown clearButton'
                                 height={"20px"}
                                 tabIndex={"1"}
                                 alt='Add'
                                 onClick={()=> clearInput(index,"background")}
                            />
                            
                          }
                        </div>
                          {
                            row.customBgDropClick &&
                            <div className="dropdownOptions">
                              {
                                selectedImages.length===0 ?
                                <div onClick={handleUploadSidebarButtonClick}>No Images Click to Upload</div> :
                                selectedImages.map((image,i)=>{
                                  if(image.name.toLowerCase().includes(row.background.toLowerCase()))
                                  return (
                                      <div>
                                        <img
                                         key={i}
                                         src={image.previewURL}
                                         alt='Option'
                                         onClick={() => handleDropdownImageClick(index,image.name)}
                                         width={"280px"}

                                        />
                                      </div>
                                  )
                                })
                              }
                            </div>
                          }
                        </div>
                      }
                      <input 
                        className='ctaInput'
                        type="text"
                        name="character"
                        value={row.character}
                        placeholder="Character Name"
                        onKeyDown={(event)=> handleKeyPress(event,index)}
                        onChange={(event) => handleConsoleInputChange(event, index)}
                      />
                      {
                        <div className="ctaInput customDropdownInput posRel">
                        <div className="inputAndCross">
                          <input
                           type='text'
                           className='searchBarForDropdown'
                           placeholder='Character Image'
                           value={row.characterImage}
                           name='characterImage'
                           onFocus={()=> handleDropdownClick(index,true,"characterImageDropClick")}
                           onKeyDown={(event)=> handleDropdownKeyPress(event,index)}
                           onChange={(e)=> handleConsoleInputChange(e,index)}
                          />
                          {
                            row.characterImageDropClick ? 
                            <img src='chevron_right_24px.svg'
                                 className='cancelDropdown'
                                 height={"20px"}
                                 tabIndex={"1"}
                                 alt='Close'
                                 onClick={()=> handleDropdownClick(index,false,"characterImageDropClick")}
                            />
                            : row.characterImage.length!=0 &&
                            <img src='add_24px.png'
                                 className='cancelDropdown clearButton'
                                 height={"20px"}
                                 tabIndex={"1"}
                                 alt='Clear'
                                 onClick={()=> clearInput(index,"characterImage")}
                            />
                          }
                        </div>
                          {
                            row.characterImageDropClick &&
                            <div className="dropdownOptions">
                              {
                                selectedImages.length===0 ?
                                <div onClick={handleUploadSidebarButtonClick}>No Images Click to Upload</div> :
                                selectedImages.map((image,i)=>{
                                  if(image.name.toLowerCase().includes(row.characterImage.toLowerCase()))
                                  return (
                                      <div>
                                        <img
                                         key={i}
                                         src={image.previewURL}
                                         onClick={() => handleCharacterDropdownImageClick(index,image.name)}
                                         width={"280px"}
                                         alt='Option'
                                        />
                                      </div>
                                  )
                                })
                              }
                            </div>
                          }
                        </div>
                      }
                      <input 
                        className='ctaInput'
                        type="text"
                        name="dialogue"
                        value={row.dialogue}                        
                        onKeyDown={(event)=> handleKeyPress(event,index)}
                        placeholder="Dialogue"
                        onChange={(event) => handleConsoleInputChange(event, index)}
                      />
                      <button
                        type="button"
                        onClick={() => handleConsoleDeleteRow(index)}
                        className="deleteRowButton deleteRow"
                      >
                      <img src="delete_24px.png" height={"24px"} alt="Delete"/>
                      </button>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleConsoleAddRow(index)}
                        className="addRowButton"
                      >
                      <img src="add_24px.png" loading="eager" alt='add'/>Add
                      </button>
                    </div>
                  ))}
            </div>  
        </div>

    </div>
    </FlashMessagesContext.Provider>
  )
}

export default BuildConsole