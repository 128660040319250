import React from 'react'

function GameScreen({speaker,character,dialogue}) {

  if(character) speaker=character;
  if(speaker.trim().length==0 && dialogue.trim().length==0)
  return (
    <div className='gameScreen'>
    <div style={{color:"#CDCDCD"}}><b >EMPTY ROW</b></div>
    </div>  
  )

  if(speaker=="None" || speaker.length==0)
  return(
    <div className="gameScreen">
    <div className='sceneDescriptionHeading' style={{marginTop:"1rem"}} ><b>SCENE DESCRIPTION</b></div>
    <div className="sceneDescription" style={{marginTop:"0rem"}}> {dialogue} </div>
    </div>    
  )

  return (
    <div className="gameScreen">
    <div className='growDiv'><div className="speakerDialogue"><b>{speaker}</b> <br/> {dialogue} </div></div>
    </div>
  )
}

export default GameScreen