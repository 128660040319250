import React, { useEffect, useState } from 'react'
import GameScreen from '../GameScreen/GameScreen';
import ImageGameScreen from '../GameScreen/ImageGameScreen';
//Css in buildConsole.css

function MobilePreview({setPreview,story,imagesMap}) {

    const [start,setStart]=useState(false);
    const [dialogueIndex,setDialogueIndex]=useState(0);
    const [currentTime, setCurrentTime] = useState((new Date().getHours())+":"+(new Date().getMinutes()));
    
    useEffect(() => {
      const timerID = setInterval(() => tick(), 60000);
  
      return function cleanup() {
        clearInterval(timerID);
      };
    });

    function tick() {
      setCurrentTime(String(new Date().getHours()).padStart(2,"0")+":"+String(new Date().getMinutes()).padStart(2,"0"));
    }

    const exitPreview = ()=> {
        setPreview(false)
        document.body.style.overflow="auto";
    }

    const handlePreviewClick = (event) => {
      event.stopPropagation();
      if(start==true && story.length==dialogueIndex)
      {
        setStart(false);
        setDialogueIndex(0);
      }
      else if(start==false) setStart(true);
      else if(start==true)  handleNextClick()
    }
    const handleNextClick = () => {
      setDialogueIndex(dialogueIndex+1);
    }

    if(imagesMap)
    {
        return (
            <div>
                <div className="mobilePreviewContainer" onClick={exitPreview}>
                <div className="mobilePreview previewMobileDarkMode" onClick={handlePreviewClick}>
                {
                    start ? 
                    (story.length>dialogueIndex
                    ?
                    <div className='sceneWithImages'>
                    <div className="mobileNavbar">{currentTime}
                      <div className="rightSection">
                        <img src='mobileNetwork.svg' alt='Mobile Network' />
                        <img src='battery.png' height={"10px"} alt='Battery' />
                      </div>
                     </div>
                    {story[dialogueIndex].background &&
                    <img src={imagesMap.get(story[dialogueIndex].background)} className='backgroundImage' alt='Background Image'/>}
                    <ImageGameScreen 
                        characterImageURL={imagesMap.get(story[dialogueIndex].characterImage)} 
                        character={story[dialogueIndex].character} 
                        dialogue={story[dialogueIndex].dialogue}
                    />
                    </div>
                    :
                    <div style={{color:"#CDCDCD"}}>CLICK TO RESTART</div> )
                    : 
                    <div className="fillerScreen">
                    <img src='wuriLogoLarge.png'  height={"60px"}/>
                    <div className="ctaButton green startButton">👆 <br/>CLICK TO CONTINUE</div>
                    </div>
                }
                </div>
                </div>
            </div>
        )
    }


  return (
    <div>    
      <div className="mobilePreviewContainer" onClick={exitPreview}>
      <div className="mobilePreview" onClick={handlePreviewClick}>
      {
        !start && 
        <div className="fillerScreen">
          <img src='wuriLogoLarge.png'  height={"60px"}/>
          <div className="ctaButton green startButton">👆 <br/>CLICK TO CONTINUE</div>
        </div>
      }
      {
        start && 
        (story.length>dialogueIndex
        ?
        <>
        <GameScreen speaker={story[dialogueIndex].speaker} dialogue={story[dialogueIndex].dialogue}/>
        </>
        :
        <div style={{color:"#CDCDCD"}}>CLICK TO RESTART</div> )
      }
      </div>
      </div>
    </div>
  )
}

export default MobilePreview