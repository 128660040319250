import { Link, useLocation, useNavigate } from 'react-router-dom';
import './header.css'

const Header = () => {
  const navigate=useNavigate('/')
  function handleLogoClick(){
    navigate('/')
  }
  const path=useLocation().pathname;

  if(path==='/privacypolicy' || path=='/buildConsole')
  return;

  return (
    <div className='navBar bodyMargin'>
      <div className="logo" onClick={handleLogoClick}><img src="/wuriLogoLarge.png" height={"50px"} alt="Wuri Logo" loading='eager'/></div>
      <div className="floatingNav">
        <Link to='/buildConsole'><div className="navLink">Build Console</div></Link>
        <Link to='/stories'><div className="navLink">Browse Stories</div></Link>
        <Link to='/assistant'><div className="navLink">Writing Assistant</div></Link>
        <Link to='/createstory'><div className="navLink">Create Story</div></Link>
      </div>
      <Link to="/contactus">
      <div className="navCTA button"><img src="/mail.svg" alt="Mail Icon"/>CONTACT US</div></Link>
    </div>
  )
};

export default Header;
