import React from 'react'
import './Footer.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
function Footer() {
  const navigate = useNavigate()
  function navigateHome(){
    navigate('/')
  }

  const path=useLocation().pathname;

  if(path=='/privacypolicy' || path=='/buildConsole')
  return;
  
  return (
    <div className='footer'>
        <div className="content">
          <div className="brand">
            <div className="logo brandLogo" onClick={navigateHome}><img src="/wuriLogoLarge.png" height={"50px"} alt="Wuri Logo" loading='eager'/></div>
            <div className="brandSummary">Wuri is a no-code story creation platform that allows users to create immersive stories without the need for any coding knowledge. With Wuri, users can create stories in just three easy steps using customizable story templates, a powerful Writing Assistant, and a Story Editor.</div>
          </div>
          <div className="contactUs">
          <div className="linkHeader"><Link to="/contactus">Contact Us</Link></div>
          <div className="linkBody">
           <a href='mailto:admin@wuri.ai'>admin@wuri.ai</a>  <br/>
          </div>
        </div>
        </div>
        <div className="copyrights">Copyrights © 2023 <span>•</span> <a href='/privacypolicy'>Privacy Policy</a></div>
    </div>
  )
}

export default Footer