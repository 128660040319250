import React, { useContext, useEffect, useState } from 'react'
import { FlashMessagesContext } from './BuildConsole';


function FlashMessages({message}) {
    const [showFlash,setShowFlash]=useState(false);
    const {setFlashMessages}=useContext(FlashMessagesContext);
    useEffect(()=>{
        setShowFlash(true);
        const timeout=setTimeout(()=>{
            setFlashMessages((previousFlashMessages)=>{
                let messages=previousFlashMessages;
                messages.shift();
                return messages;
            })
            setShowFlash(false);
        },2000)
        return () =>{
            clearTimeout(timeout)
        }
    },[])

  if(showFlash==true)
  return (
    <div className='flash-message'>
        {message}
    </div>
  )
}

export default FlashMessages