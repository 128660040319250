import React from 'react'
import './ViewGame.css'
import { Link } from 'react-router-dom';

function ViewGame({GameTitle,GameImageURL,PlayStoreURL,AppStoreURL}) {

    // const DefaultAppStoreURL = "https://apps.apple.com/us/app/wuri-games-for-everyone/id6450294532";
    const DefaultPlayStoreURL = "https://play.google.com/store/apps/details?id=ai.wuri.portal";


  return (
    <div className='viewGameContainer'>
        <div className="gameContent">
            <img src={"/"+GameImageURL} className='gameImage' width={"400rem"}/>
            <div className="linksAndHeaders">
                <div className="sectionHeader gameTitle">
                    {GameTitle}
                </div>
                <div className="free">
                    Available for Free
                </div>
                <div className="downloadButtons">
                    <div className="downloadButton googlePlayButton">
                        <a href={PlayStoreURL ?  PlayStoreURL : DefaultPlayStoreURL} target='_blank'>
                            <img src='/google-play-badge.png' className='' width={"340rem"} alt='Google Play Store'/>
                        </a>
                    </div>  
                    {/* <div className="downloadButton appleStoreButton">
                        <a href={AppStoreURL? AppStoreURL : DefaultAppStoreURL} target='_blank'>
                            <img src='/app-store-badge.svg' className='' width={"300rem"} alt='App Store Badge'/>
                        </a>
                    </div> */}
                </div>
            </div>
        </div>
        {
            GameTitle!=="Wuri: Visual Stories" &&
            <Link to="/stories">
            <div className="ghostButton browseGamesButton">
            Browse More Stories
            </div>
            </Link>
        }
    </div>
  )
}

export default ViewGame