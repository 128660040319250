import firebase from "firebase/compat/app";
import { getAnalytics } from "firebase/analytics";
import "firebase/compat/firestore";
import "firebase/compat/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBzvVcQiIbMD9IciaxVRuq_gl0uKMVa0u0",
    authDomain: "wuri-bccd5.firebaseapp.com",
    projectId: "wuri-bccd5",
    storageBucket: "wuri-bccd5.appspot.com",
    messagingSenderId: "97550002387",
    appId: "1:97550002387:web:ecd16362cfc335f70b8c26",
    measurementId: "G-BLBNN8HP49"
  };

const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = firebase.firestore();
export const storage = firebase.storage();
