import React from 'react'
import { useNavigate } from 'react-router-dom';

function ConfirmBox({setShowConfirm}) {

  const navigate = useNavigate();
  
  return (
    <div className="confirm">
    <div className='mobilePreviewContainer modalScreenContainer' onClick={()=> setShowConfirm(false)}>
      <div className="modalScreen " onClick={(e)=> e.stopPropagation()}>
        <div className="modalScreenHeader">
          <div className="content">Confirm Exit</div>
          <div className="closeModal"><img src="highlight_off_24px.png" loading='eager' onClick={()=>setShowConfirm(false)}/></div>
        </div>
        <div className="modalBodyContent">
          <div className="bodyContent">
          Are you sure you want to leave the Console ?
          </div>
          <div className="choiceButtons">
            <div className="choiceButton green" onClick={()=> setShowConfirm(false)}>
            No, stay in Console
            </div>
            <div className="choiceButton" onClick={()=> navigate('/')}>
            Yes, exit
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ConfirmBox