import { useEffect } from 'react'
import './browseGames.css'

function BrowseGames() {

    useEffect(()=>{
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      },[])

  return (
    <div>
        <div className="carouselSection" >
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel" data-ride="carousel" data-interval="2000" >
        <ol class="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
            <a href='#gameSection'>
            <img class="d-block m-auto" className='skeleton' src="Carousel-Slide-1.png" alt="First slide" />
            </a>
            </div>
            <div class="carousel-item ">
            <a href='#gameSection'>
            <img class="d-block" className='skeleton' src="Carousel-Slide-2.png" alt="Second slide" />
            </a>
            </div>
            <div class="carousel-item ">
            <a href='#gameSection'>
            <img class="d-block" className='skeleton' src="Carousel-Slide-3.png" alt="Third slide" />
            </a>
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
        </div>
        </div>
        <div className="featuredButton">
            FEATURED
        </div>
        <div className="gamesSection" id='gameSection'>
            <div className="gameBlock">
            <div className="gameName">STARTUP CHOICES OF A FOUNDER</div>
            <img src="/Startup_Thumbnail_2.png" width={"400rem"} className='skeleton' alt="Startup - Choices of a Founder"/>
            <div className="playButton">
                {/* <a href="https://apps.apple.com/us/app/wuri-games-for-everyone/id6450294532" target='_blank'><img src='/app-store-badge.svg' className='' width={"270rem"} alt='App Store Badge'/></a> */}
                <a target="_blank" href='https://play.google.com/store/apps/details?id=global.campyellow.episodes&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
            </div>
            </div>
            <div className="gameBlock">
            <div className="gameName">A HOLLYWOOD DREAM</div>
            <img src="/Thumbnail_1.png" width={"400rem"} className='skeleton' alt="A HOLLYWOOD DREAM"/>
            <div className="playButton">
                {/* <a href="https://apps.apple.com/us/app/wuri-games-for-everyone/id6450294532" target='_blank'><img src='/app-store-badge.svg' className='' width={"270rem"} alt='App Store Badge'/></a> */}
                <a target="_blank" href='https://play.google.com/store/apps/details?id=ai.wuri.portal'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                </div>
            </div>

            <div className="gameBlock">
            <div className="gameName">FINDING TAYLOR</div>
            <img src="/Thumbnail_3.png" width={"400rem"} className='skeleton' alt="Finding Taylor"/>
            <div className="playButton">
                {/* <a href="https://apps.apple.com/us/app/wuri-games-for-everyone/id6450294532" target='_blank'><img src='/app-store-badge.svg' className='' width={"270rem"} alt='App Store Badge'/></a> */}
                <a target="_blank" href='https://play.google.com/store/apps/details?id=ai.wuri.portal'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
            </div>
            </div>
        </div>  
    </div>
  )
}

export default BrowseGames