import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import './contactUs.css'
import { db } from '../../firebase';
import { useState,useEffect } from 'react';

function ContactUs() {

  const [ctaClick,setCtaClick] = useState(true);
  const [email,setEmail]=useState();
  const [submitted,setSubmitted]=useState(false);
  const [message,setMessage]=useState();
  const [flashMessage,setFlashMessage]=useState("INVALID EMAIL");

  function preset(){
    setSubmitted(false)
    setMessage("")
  }

  useEffect(()=>{
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  },[])

  async function handleSubmit(e){
    setMessage("")
    e.preventDefault()
    setSubmitted(true)

    if(email.length!=0 &&
       email.indexOf("@")!=-1 &&
       email.indexOf("@")==email.lastIndexOf("@") &&
       email.slice(email.indexOf("@")+1).indexOf(".")>0 &&
       email.slice(email.indexOf(".")+1).length!=0)
    {
      try{
      await addDoc(collection(db ,"emails"),{email,timeStamp:serverTimestamp()}).then(()=>{  
      setMessage("WE'LL NOTIFY YOU")})
      }
      catch(e){
          setMessage("TRY AGAIN")
      }
    }
    else
    {
      setFlashMessage("INVALID EMAIL")
      setMessage("INVALID EMAIL")
    }
  }



  return (
    <div className='contactusContainer'>
        <div className="contactUsHeading">Contact Us</div>
        <div className="contactUsContent">Any Questions or remarks just write us a message</div>
        <div className="contactUsMainBlock">
          <div className="blueBlock">
            <div className="getInTouch">Get in touch</div>
            <div className="subsection">Chat With Us</div>
            <div className="subsectionContent">Our team is here to help</div>
            <div className="email"><a href='mailto:admin@wuri.ai'>admin@wuri.ai</a></div>
          </div>
          <div className="signUpFormBlock">
            <div className="emailSvg"><img src="/contactUsMailOutline.svg"/></div>
            <div className="emailInput">
            {
              submitted ? 
              (message.length==0 
              ? 
              <div className="ctaButton green"> <div className="loader"></div> </div> :
              message==="INVALID EMAIL"
              ?
              <div className="ctaButton red" onClick={preset}><img src="refresh_24px.png" alt="tick" style={{marginRight:"1rem"}}/>INVALID EMAIL</div> 
              :
              message==="WE'LL NOTIFY YOU" && <div className="ctaButton green" ><img src="tick.svg" alt="tick" style={{marginRight:"1rem"}}/>Thanks for Connecting </div>) 
              :
              ctaClick && <form className='emailForm' onSubmit={handleSubmit}><input type='email' onChange={(e)=>setEmail(e.target.value)} value={email} className='ctaInput' placeholder='Enter Email'/><button className="ctaButton contactUsButton" type='submit' onClick={()=> setCtaClick(true)}>Let's Connect</button></form>
            } 
            </div>
          </div>
        </div>
    </div>
  )
}

export default ContactUs