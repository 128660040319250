import { useState,useEffect } from 'react'
import './home.css'
import { collection,addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import { AnimationOnScroll } from 'react-animation-on-scroll';

function Home() {

  const [ctaClick,setCtaClick] = useState(false);
  const [email,setEmail]=useState();
  const [submitted,setSubmitted]=useState(false);
  const [message,setMessage]=useState();
  const [flashMessage,setFlashMessage]=useState("INVALID EMAIL");

  useEffect(()=>{
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  },[])

  function preset(){
    setSubmitted(false)
    setCtaClick(true)
    setMessage("")
  }

  async function handleSubmit(e){
    setMessage("")
    setCtaClick(true)
    e.preventDefault()
    setSubmitted(true)

    if(email.length!=0 &&
       email.indexOf("@")!=-1 &&
       email.indexOf("@")==email.lastIndexOf("@") &&
       email.slice(email.indexOf("@")+1).indexOf(".")>0 &&
       email.slice(email.indexOf(".")+1).length!=0)
    {
      try{
      await addDoc(collection(db ,"emails"),{email,timeStamp:serverTimestamp()}).then(()=>{  
      setMessage("WE'LL NOTIFY YOU")})
      }
      catch(e){
          setMessage("TRY AGAIN")
      }
    }
    else
    {
      setFlashMessage("INVALID EMAIL")
      setMessage("INVALID EMAIL")
    }
  }


  return (
    <div>
      <div className="bodyMargin heroSection">
      <div className="introText"></div>
          <div className="brandText"><img src="wuriLogoLarge.png" alt="Wuri Logo" /></div>
          <div className="subText">
            <span className="orangeSubText">
              Visual Stories
            </span>
            <span className="greySubText" style={{color:"#575757"}}>
            </span>
          </div>
          <div className="buttons">
          {
            submitted&&ctaClick ? 
            (message.length==0 ? 

            <div className="ctaButton green"> <div className="loader"></div> </div> :
            message==="INVALID EMAIL" ?

            <div className="ctaButton red" onClick={preset}><img src="refresh_24px.png" alt="tick" style={{marginRight:"1rem"}}/>INVALID EMAIL</div> :

            message==="WE'LL NOTIFY YOU" && <div className="ctaButton green" ><img src="tick.svg" alt="tick" style={{marginRight:"1rem"}}/>WE'LL NOTIFY YOU</div>) :

            ctaClick ? <form className='emailForm' onSubmit={handleSubmit}><input type='email' onChange={(e)=>setEmail(e.target.value)} value={email} className='ctaInput' placeholder='Enter Email'/><img className='emailButton' src="chevron_right_24px_black.svg" alt="Right Arrow" onClick={handleSubmit}/></form> :

            <div className="ctaButton earlyAccessB" onClick={()=> setCtaClick(true)}>GET  EARLY ACCESS <img  src="chevron_right_24px.svg" alt="Right Arrow"/></div>
          } 
            <Link className='ghostButton' to="/stories">
            <img src="satisfied.svg" href="" style={{marginRight:"1rem"}} alt="Happy face" loading='eager'/>
              EXPLORE STORIES
              </Link>
          </div>
      </div>

      {/* <div className="section1">
          <div className="horizontalRow"></div>
          <div className="sectionHeader">Simple and Fast 3 Step Process</div>
          <div className="sectionContent">Create your own stories in just three clicks with Wuri</div>
          <div className="sectionImage"><img src="process.png" alt="Right Arrow" loading='lazy'/></div>
      </div> */}

      {/* <div className="section2">
          <AnimationOnScroll animateOnce={true} animateIn='animate__fadeIn'>
          <div className="textContent">
            <div className="sectionHeader left">Craft Immersive <br/> Narratives</div>
            <div className="sectionContent left"  >Introducing Wuri's Story Editor - the feature that allows you to create immersive stories with ease. With our manual story creation tool, you can craft your own narrative, characters, and dialogues to create unique and immersive gaming experiences.</div>
            <div className="sectionLink"><Link to="/storyeditor">Try Now<img src="arrow_right_24px.png" id='rightArrow'/></Link></div>
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateOnce={true} animateIn='animate__backInRight'>
            <div className="sectionImage"><img src="storyeditor.png" alt="Right Arrow" loading='eager'/></div>
          </AnimationOnScroll>
      </div> */}

      {/* <div className="section3 section2">
          <AnimationOnScroll animateOnce={true} animateIn='animate__backInLeft'>
            <div className="sectionImageRight"><img src="assistant.png" alt="AI Virtual Assistant Image" loading='lazy'/></div>
          </AnimationOnScroll>
          <AnimationOnScroll animateOnce={true} animateIn='animate__fadeIn'>
            <div className="textContent">
              <div className="sectionHeader left">Need Some <br/>Help Use AI</div>
              <div className="sectionContent left">Our Writing Assistant is designed to make story development accessible to everyone, regardless of their writing skills or experience. Whether you're a seasoned writer or a beginner, Wuri's Writing Assistant can help you bring your story ideas to life with ease.</div>
              <div className="sectionLink"><Link to="/assistant">Try Now<img src="arrow_right_24px.png" id='rightArrow'/></Link></div>
            </div>
          </AnimationOnScroll>
      </div> */}

    </div>

  )
}

export default Home