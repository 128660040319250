import React from 'react'
import { Link } from 'react-router-dom'

function Error() {
  return (
    <div>
        <div className="errorContainer">
            <div className="errorText" style={{color:"white",minHeight:"400px",padding:"8rem 0"}}>
                <div className="errorLightText subText" style={{marginBottom:"3rem"}}>Ooops...</div>
                <div className="error subText green" style={{marginBottom:"3rem"}}>404</div>
                <div className="errorHeavyText subText" style={{marginBottom:"3rem"}}>PAGE NOT FOUND</div>
                <Link to='/'> <div className="ghostButton errorButton" style={{justifyContent:"center",width:"50%",margin:"auto"}}> BACK TO HOME PAGE</div></Link>
            </div>
        </div>
    </div>
  )
}

export default Error