import { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from "react-responsive";
import chatGPT from '../../utils/chatGPT';
import './chat.css'
import { db } from "../../firebase";
import firebase from "firebase/compat/app";
import { Link } from 'react-router-dom';
import GameScreen from '../GameScreen/GameScreen';
import MobilePreview from '../MobilePreview/MobilePreview';

const initialPrompt = 'Start writing a 2 hour movie script where each response describes a small part of the movie in the following format. Have multiple characters in the story introduce each character slowly. Each script line starts with symbol "###" for easy text parsing. "###" symbol is followed by name of the character speaking a dialogue or "None" if the script is explaining the scene. This is followed by delimiter ":" and then the text of the script. Describe first minute for';
const continuePrompt = "continue the script for the next 1 minute";

const Chat = () => {
  var lastIndex = 0;
  const [inputMessage, setInputMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([{ role: 'system', content: initialPrompt }]);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [continueClick,setContinueClick]=useState(false)
  const tableRef = useRef(null);
  const [userAgent, setUserAgent] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isBoring, setIsBoring] = useState(false);
  const [isOffensive, setIsOffensive] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  //Mobile Preview State Variables
  const [formRows, setFormRows] = useState();
  const [preview,setPreview] = useState(false);
  const [start,setStart] = useState(false);
  const [dialogueIndex,setDialogueIndex]=useState(0);
  const [story,setStory]=useState();

  useEffect(() => {
    setUserAgent(navigator.userAgent);
  }, []);

  useEffect(()=>{
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  },[])

  const downloadCSV = () => {
    const csvRows = [];
    const table = tableRef.current;
    const rows = table.querySelectorAll("tr");

    // Construct CSV string row by row
    for (let i = 0; i < rows.length; i++) {
      const csvRow = [];
      const cells = rows[i].querySelectorAll("td, th");
      for (let j = 0; j < cells.length; j++) {
        csvRow.push(cells[j].innerText);
      }
      csvRows.push(csvRow.join(","));
    }

    // Create a CSV Blob and download it
    const csvBlob = new Blob([csvRows.join("\n")], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvBlob);
    const hiddenLink = document.createElement("a");
    hiddenLink.href = csvUrl;
    hiddenLink.download = "table.csv";
    hiddenLink.click();
    URL.revokeObjectURL(csvUrl);
  };

  const sendMessage = async (e, inputMsg = inputMessage) => {
    e.preventDefault();

    setInputDisabled(true);

    setLoading(true);

    lastIndex = 0;

    var messages = chatHistory.concat({ role: 'user', content: inputMsg })

    const chatGPTResponse = await chatGPT(messages);

    messages = messages.concat({ role: 'assistant', content: chatGPTResponse })

    // Initializing the formRows with Latest  CHat History
    const responseRows = chatGPTResponse
    .split("###")
    .slice(1)
    .map((line) => {
      const [speaker, dialogue] = line.split(":");
      return { speaker: speaker.trim(), dialogue: dialogue.trim() };
    });

    setFormRows(() => [ ...responseRows]);

    setChatHistory(messages);

    setLoading(false);
    setContinueClick(false)

    setFeedbackSubmitted(false);
    setIsLiked(false);
    setIsBoring(false);
    setIsOffensive(false);
  };

  const continueStory = async (event) => {
    event.stopPropagation();
    setContinueClick(true)
    await sendMessage({ preventDefault: () => {} }, continuePrompt);
  };

  const handleLike = async () => {
    processUserRating("like");
    setIsLiked(true);
  }

  const handleBoring = async () => {
    processUserRating("boring");
    setIsBoring(true);
  }

  const handleOffensive = async () => {
    processUserRating("offensive");
    setIsOffensive(true);
  }

  const processUserRating = async ( userRating ) => {
    const projectRef = await db.collection("feedbacks").add({
      created: firebase.firestore.FieldValue.serverTimestamp(),
      userAgent: userAgent,
      userRating: userRating,
      messages: chatHistory,
    });
    setFeedbackSubmitted(true);
  }

  const triggerPreview = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    console.log("Hi")
    setPreview(!preview);
    setStory(formRows);
    setStart(false);
    setDialogueIndex(0);
    if(document.body.style.overflow=="hidden") document.body.style.overflow="auto";
    else document.body.style.overflow="hidden";
  }

  return (
    <div>
      <div className='onMobile'>
      <div className="errorContainer">
        <div className="errorText" style={{color:"white",minHeight:"400px",padding:"8rem 0"}}>
            <div className="errorLightText subText" style={{marginBottom:"3rem"}}>Best viewed on desktop</div>
            <div className="error subText green" style={{marginBottom:"3rem"}}>SWITCH TO DESKTOP VIEW FOR BETTER EXPERIENCE</div>
            <div className="errorHeavyText subText" style={{marginBottom:"3rem"}}></div>
            <Link to='/'> <div className="ghostButton errorButton" style={{justifyContent:"center",width:"50%",margin:"auto"}}> BACK TO HOME PAGE</div></Link>
        </div>
      </div>
      </div>
      {
        preview &&     
        <MobilePreview setPreview={setPreview} story={story} />
      }
      <div className="section">
      <div className="assistantContent">
      <div className="sectionHeader assistantHeader">AI Writing Assistant</div>
      <div className="sectionContent assistantBody">Craft Game narratives using Prompts</div>
      </div>
      <form onSubmit={sendMessage}>
      <input
        type="text"
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
        className='ctaInput'
        placeholder='Enter a story scenario like "Journey of a startup founder starting from leaving the job"'
      />
      <button type="submit" disabled={inputDisabled} className='ctaButton blue'>
        Create
      </button>
      </form>
      {loading && <div class="loader assistantLoader"></div>}
      {(loading && !continueClick) && 
      <div class="viewStory">
        <div className="topButtons">
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
        </div>
        <div className="storyContent">
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
        </div>
      </div>

      }
      {
        inputDisabled && (!loading || continueClick) &&
        <div className="viewStory">
        <div className="topButtons">
        <button type="submit" onClick={downloadCSV} className='ctaButton downloadcsv'> Download CSV</button>
        <button type="button" onClick={triggerPreview}  className='ctaButton resetFormButton'>Mobile Preview</button>
        <button type="button" onClick={continueStory} className="ctaButton resetFormButton">Continue +</button>
        </div>
        <div className="storyContent">
        {inputDisabled && (
          <table ref={tableRef}>
            {chatHistory.filter((message) => message.role === "assistant").length > 0 && (
              <thead>
                <tr>
                  <th>Scene Number</th>
                  <th>Character Name</th>
                  <th>Text</th>
                </tr>
              </thead>
            )}
            {chatHistory.filter((message) => message.role === "assistant").map((message, index) => (
              <tbody>
                {` ${message.content}`.split("###").slice(1).map((line, index) => {
                  const [characterName, text] = line.split(":");
                  lastIndex += 1;
                  return [lastIndex, characterName.trim().toLowerCase() === "none" ? "" : characterName, text]
                }).map(([index, characterName, text]) => (
                  <tr key={index}>
                    <td style={{display:"none"}}>{index}</td>
                    <td style={{minWidth:"200px",textAlign:"center"}}>{characterName}</td>
                    <td>{text}</td>
                  </tr>
                ))}
              </tbody>
            ))}
          <button type="button" onClick={continueStory} className="ctaButton resetFormButton">Continue</button>
          </table>
        )}
        </div>
        <div className="reviewButton">

        </div>
        </div>
      }
      <div className="sectionFooter assistantFooter">Currently in Beta</div>
      </div>
    </div>
  );
};

export default Chat;
