import React from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import Home from './components/Home/Home';
import BrowseGames from './components/BrowseGames/BrowseGames';
import Error from './components/Error/Error';
import StoryEditor from './components/StoryEditor/StoryEditor';
import Assistant from './components/Assistant/Assistant';
import GenerateAssets from './components/StoryGenerator/GenerateAssets';
import CreateStory from './components/CreateStory/CreateStory';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import ContactUs from './components/ContactUs/ContactUs';
import BuildConsole from './components/BuildConsole/BuildConsole';
import ViewGame from './components/ViewGame/ViewGame';

function App() {

  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/stories" exact element={<BrowseGames />} />
          <Route path="/stories/startup" exact element={<ViewGame GameTitle="Startup Choices of A Founder" PlayStoreURL="https://play.google.com/store/apps/details?id=global.campyellow.episodes&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" AppStoreURL="" GameImageURL="Startup_Thumbnail_2.png" />} />
          <Route path="/stories/hollywood" exact element={<ViewGame GameTitle="A Hollywood Dream" PlayStoreURL="" AppStoreURL="" GameImageURL="Thumbnail_1.png" />} />
          <Route path="/stories/wuri" exact element={<ViewGame GameTitle="Wuri: Visual Stories" GameImageURL="wuriMobileApp.png" PlayStoreURL="" AppStoreURL=""/>} />
          <Route path="/stories/taylor" exact element={<ViewGame GameTitle="Finding Taylor A Swiftie' s Experience" GameImageURL="Thumbnail_3.png"/>} />
          <Route path="/buildconsole" exact element={<BuildConsole />} />
          <Route path="/createstory" exact element={<CreateStory />} />
          <Route path="/storyEditor" exact element={<StoryEditor />} />
          <Route path="/assistant" exact element={<GenerateAssets />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/contactus' element={<ContactUs />} />
          <Route path="*" element={<Navigate to="/not-found" replace />} />
          <Route path='/not-found' element={<Error />} />
        </Routes>
        <Footer />
      </Router>
    <Analytics />
    </div>
  );
}

export default App;
