import './assistant.css'

function Assistant() {

  const handleChange = () =>{

  }

  return (
    <div className='searchForm'>
     <div className="searchPageContent">
     <div className=" sectionHeader formHeader">AI Virtual Assistant</div>
     <div className=" sectionContent formContent">Craft Narratives using Prompts</div>
     </div>   
     <form>
      <input type='text' className='ctaInput ' onChange={handleChange} />
      <button className='ctaButton searchButton'>Create</button>
     </form>
     <div className="sectionFooter">Currently in Beta</div>
    </div>
  )
}

export default Assistant