import { Link } from 'react-router-dom'
import './createStory.css'


function CreateStory() {
  return (
    <div>
    <div className="createStorySection">
      <div className="sectionHeader">Create your own Story</div>
      <div className="sectionContent">You can either write your story manually using Wuri’s story editor or use our AI-powered Virtual Assistant to generate a unique story tailored to your preferences</div>
      <div className="sectionButtons">
      <Link to='/assistant'>
        <button className="ctaButton"><img src='android_24px_rounded.svg'/>Create Using Writing Assistant</button>
      </Link>
        <div className="or">or</div>
      <Link to="/storyEditor">
        <button className="ctaButton"><img src='calendar_view_day_24px.png'/>Create Using Wuri’s Story Editor</button>
      </Link>
      </div>
    </div>
    </div>
  )
}

export default CreateStory