import React, { useEffect, useState } from 'react'
import { db, storage } from '../../firebase';
import Papa from 'papaparse';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
//CSS in buildConsole.css

function SubmitForm({setShowNextScreen,selectedImages,consoleFormRows}) {



  const [csvFileUploadProgress,setCsvFileUploadProgress]=useState();
  const [zipFileUploadProgress,setZipFileUploadProgress]=useState();
  const [email,setEmail]=useState("")
  const [message,setMessage]=useState("")
  const [loading,setLoading]=useState(false);
  const [uploadMessage,setUploadMessage]=useState("")

  useEffect(()=>{
    const myTimeout=setTimeout(()=>{
      setMessage("")
    },[4000])
    return ()=>{
      clearTimeout(myTimeout)
    }
  },[message])

  //To create CSV File
  function createCsvContent(data) {
    const csv = Papa.unparse(data);
    return csv;
  }

  //To Create Zip File
  const createZipFile = async ()=>{
    const zip = new JSZip();
    
    const downloadImages = selectedImages.map(async(image)=>{
        const response = await fetch(image.previewURL);
        const blob = await response.blob();
        zip.file(image.name,blob);
    })
    await Promise.all(downloadImages);
    return await zip.generateAsync({ type: 'blob' });
  }

  //Uploading CSV File to the Firebase storage
  const uploadCSVFile = async () => {
    setUploadMessage("Creating CSV File")
    //Chosing the data required for Story CSV or Excluding the Dropdown Click Handlers
    const csvData=consoleFormRows.map((consoleFormRow,index)=>{
      return ({background:consoleFormRow.background,
               speakerName:consoleFormRow.character,
               speakerImage:consoleFormRow.characterImage,
               dialogue:consoleFormRow.dialogue,
              })}
            )
    //Converting the filtered data to CSV File
    const csvContent = createCsvContent(csvData);
    //Converting File to Blob so that it can be uploaded to the storage
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    try{
      const fileName= `${Date.now()}.csv`;
      const storageRef=storage.ref().child(`/buildConsoleSubmissions/csvs/${fileName}`)
      const uploadTask=storageRef.put(blob);
      uploadTask.on('state_changed',(snapshot)=>{
        const progress =Math.round( (snapshot.bytesTransferred / snapshot.totalBytes)*100)
        setCsvFileUploadProgress(progress);
        setUploadMessage("Uploading CSV File ",progress," %")
      })
      await uploadTask;
      setUploadMessage("CSV File Uploaded")
      const csvURL=await storageRef.getDownloadURL();
      return csvURL;
    }
    catch(e){
      console.log("Error While Uploading Csv File ",e)
      setUploadMessage("Error in Uploading Csv file")
      setLoading(false);
    }

  }
  //Upload zip file to firebase storage
  const uploadZipFile=async()=>{

    try{
      setUploadMessage("Creating ZIP File")
      const zip = await createZipFile();
      const zipFileName=`${Date.now()}.zip`;
      const zipStorageRef=storage.ref("/buildConsoleSubmissions/zips/").child(zipFileName)
      const uploadTask = zipStorageRef.put(zip);
      setUploadMessage("Uploading ZIP File")
      uploadTask.on("state_changed",(snapshot)=>{
        const progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes)*100);
        setZipFileUploadProgress(progress);
      })
      await uploadTask;
      setUploadMessage("ZIP File Uploaded ")

      const zipUrl=await zipStorageRef.getDownloadURL()
      return zipUrl;
    }
    catch(e){
      console.log("Error Uploading the zip file ",e);
      setUploadMessage("Error in Uploading ZIP file")
      setLoading(false)
    }
  }

  //Upload Files to Firebase storage and store the download urls
  const uploadFiles = async () => {

    await uploadCSVFile();
    await uploadZipFile();

  };

  const handleSubmit = async(e)=>{
    e.preventDefault();
    if(email.length!=0 &&
      email.indexOf("@")!=-1 &&
      email.indexOf("@")==email.lastIndexOf("@") &&
      email.slice(email.indexOf("@")+1).indexOf(".")>0 &&
      email.slice(email.indexOf(".")+1).length!=0)
    {
      setLoading(true);
      setUploadMessage("Uploading Files ")
      const csvUrl=await uploadCSVFile()
      const zipUrl=await uploadZipFile()
      try{
        setUploadMessage("Storing Email")
        await addDoc(collection(db ,"buildConsoleSubmissions"),{email:email,zipFileUrl:zipUrl,csvUrl:csvUrl,timeStamp:serverTimestamp()})
        setLoading(false);
        setMessage("Story Uploaded")
        setCsvFileUploadProgress(0)
        setZipFileUploadProgress(0)
        }
        catch(e){
          console.log(e)          
          setMessage("Error In Uploading Story")
          setLoading(false)
        }
    } 
    else 
    setMessage("Error Invalid Email")
  
  }

  return (
    <div>
    <div className='mobilePreviewContainer nextScreenContainer' onClick={()=> setShowNextScreen(false)}>
    {message.length!=0 && 
      <div className={message.substring(0,5)==="Error"?"consoleFlashMessage red" : "consoleFlashMessage green"} onClick={()=> setMessage("")}>
       {message}
       <br/>
       {/* <span style={{fontSize:"10px"}}>Click to disappear</span> */}
      </div>
    }
        <div className="nextScreen " onClick={(e)=> e.stopPropagation()}>
        <div className="submitContainerHeader">
          <div className="content">Submit your Story for Review</div>
          <div className="closeModal"><img src="highlight_off_24px.png" onClick={()=> setShowNextScreen(false)}/></div>
        </div>
        <div className="submitContentContainer">
          <div>
          <div className="submitContent">You've Done your part</div>
              <div className="submitContent">Now, Let us handle the rest</div>
              <div className="submitContent">Share your build,</div>
              <div className="submitContent">sit back,</div>
              <div className='submitContent'>and expect an update from us soon.</div>
          </div>
          <div className="submitImage">
            <img src='relax.jpg' width={400} />
          </div>
        </div>
        {
          loading ? 
            <>
            <div className="loader"></div>
            <div className="loaderMessage">{uploadMessage+" "}
                                           {zipFileUploadProgress!=0 && zipFileUploadProgress!=100 && zipFileUploadProgress+"%"}
                                           {csvFileUploadProgress!=0 && csvFileUploadProgress!=100 && csvFileUploadProgress+"%"}
            </div>
            </>
          :
          <form onSubmit={handleSubmit}>
          <input id='email'
                type='email'
                placeholder='EMAIL' 
                className='modalEmailInput' 
                value={email}
                onChange={(e)=> setEmail(e.target.value)} 
                required
              />
          <button type='submit' className="ctaButton uploadButton" onClick={handleSubmit}>Submit</button>
        </form>
        }
        </div>
      </div>
    </div>
  )
}

export default SubmitForm