import axios from 'axios';

const chatGPT = async (messages) => {
  const API_KEY = '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';

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${API_KEY}`,
  };

  const data = {
    model: "gpt-3.5-turbo",
    messages: messages,
    temperature: 1,
  };

  try {
    const response = await axios.post('https://api.openai.com/v1/chat/completions', data, { headers });
    const messageResponse = response.data.choices[0].message.content.trim();
    return messageResponse;
  } catch (error) {
    console.error('Error calling ChatGPT API:', error);
    return 'Error: Unable to fetch response from ChatGPT';
  }
};

export default chatGPT;
